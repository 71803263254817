import { v4 as uuidv4 } from "uuid";

const songData = [
    {
        title: "Wake Me Up",
        artist: "Avicii",
        coverUrl:
            "https://api.audiohut.ackr8.com/Electronic/1.png",
        thumbUrl:
            "https://api.audiohut.ackr8.com/Electronic/1.png",
        audio: "https://api.audiohut.ackr8.com/Electronic/1.mp3",
        palette: "coral",
        id: uuidv4(),
    },
    {
        title: "Dont Let Me Let Go",
        artist: "Dillon Francis",
        coverUrl:
            "https://api.audiohut.ackr8.com/Electronic/2.jpg",
        thumbUrl:
            "https://api.audiohut.ackr8.com/Electronic/2.jpg",
        audio: "https://api.audiohut.ackr8.com/Electronic/2.mp3",
        palette: "yellow",
        id: uuidv4(),
    },
    {
        title: "Lean On",
        artist: "DJ Snake",
        coverUrl:
            "https://api.audiohut.ackr8.com/Electronic/3.jpg",
        thumbUrl:
            "https://api.audiohut.ackr8.com/Electronic/3.jpg",
        audio: "https://api.audiohut.ackr8.com/Electronic/3.mp3",
        palette: "purple",
        id: uuidv4(),
    },
    {
        title: "Dont Forget My Love",
        artist: "Diplo Miguel",
        coverUrl:
            "https://api.audiohut.ackr8.com/Electronic/4.jpg",
        thumbUrl:
            "https://api.audiohut.ackr8.com/Electronic/4.jpg",
        audio: "https://api.audiohut.ackr8.com/Electronic/4.mp3",
        palette: "green",
        id: uuidv4(),
    },

    {
        title: "Why Dont We DOnt wake me up",
        artist: "Jonas Blue",
        coverUrl:
            "https://api.audiohut.ackr8.com/Electronic/5.jpg",
        thumbUrl:
            "https://api.audiohut.ackr8.com/Electronic/5.jpg",
        audio: "https://api.audiohut.ackr8.com/Electronic/5.mp3",
        palette: "purple",
        id: uuidv4(),
    },
];

export default songData;